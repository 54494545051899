.alert {
  background-color: rgba($colorLightBlue, 0.9);
  padding: $paddingTopBottom 0;
  width: 100%;
  color: $colorBlue;

  h2 {
    margin-top: 0;
  }

  .date {
    margin-left: -4px;
    margin-bottom: 4px;

    span {
      margin-right: 0;
    }

    span, p {
      display: inline;
    }
  }
}

@include media-breakpoint-up(md) {
  .alert {
    .date {
      margin-left: -42px;
      margin-bottom: 0;

      span {
        margin-right: 10px;
      }
    }
  }
}
