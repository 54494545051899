.janewi-eventsblog {
  .janewi-eventsblog-event {
    margin-bottom: 20px;

    h2 {
      margin-top: 0;
    }
  }

  .janewi-eventsblog-featured {
    padding: 0 20px 20px;
    box-shadow: 0 0 5px #ddd;
    margin: 0 0 30px 0;
    text-align: left;
    height: calc(100% - 30px);
    position: relative;
    border-radius: 9px;
    display: flex;
    flex-direction: column;

    .janewi-eventsblog-featured-image {
      width: calc(100% + 40px);
      height: 250px;
      background-size: cover;
      background-position: center center;
      margin: 0 -20px;
      border-radius: 9px 9px 0 0;
      position: relative;

      img {
        min-width: 100%;
        min-height: 100%;
        width: auto;
      }
    }

    .janewi-eventsblog-featured-header {
      padding: 10px 0 0 0;

      .janewi-eventsblog-featured-title {
        padding: 0;
        margin: 10px 0 5px 0;
      }
    }

    .janewi-eventsblog-featured-content {
      padding: 10px 0 5px 0;
      flex-grow: 1;
    }

    .janewi-eventsblog-featured-footer {
      padding: 10px 0 0 0;

      .janewi-eventsblog-featured-date {
        font-size: $fontSizeSmall;
        padding: 0 0 0 6px;
        line-height: 2.3rem;

        &:before {
          content: "\E046";
          position: relative;
          bottom: -1px;
          right: 5px;
          font-size: 11px;
          font-family: "Glyphicons Regular";
        }
      }

      .col:last-child {
        text-align: right;
      }
    }
  }
}

@include media-breakpoint-down('md') {
  .janewi-eventsblog {
    .janewi-eventsblog-event {
      margin-bottom: 35px;

      h3 {
        margin-top: 10px;
      }
    }

    .janewi-eventsblog-featured {
      margin-bottom: 50px;
    }
  }
}
