.link-arrowed {
  display: inline-block;
  line-height: 2rem;

  .arrow-icon {
    stroke: $colorBlue;
    position: relative;
    top: -1px;
    vertical-align: middle;
    @include default_transition('transform', 'stroke');

    .arrow-icon--circle {
      @include default_transition('stroke-dashoffset');
      stroke-dasharray: 95;
      stroke-dashoffset: 95;
    }
  }

  &:hover {
    .arrow-icon {
      stroke: $colorYellow;
      transform: translate3d(5px, 0, 0);

      .arrow-icon--circle {
        stroke-dashoffset: 0;
      }
    }
  }
}
