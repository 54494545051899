@mixin default_transition($properties...) {
  $t: "";
  @each $property in $properties {
    $index: index($properties, $property);
    @if $index < 2 {
      $t: #{$property} ease-in-out 300ms
    } @else {
      $t: #{$t}, #{$property} ease-in-out 300ms
    }
  };
  transition: $t;
}