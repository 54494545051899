header {
  background-color: $colorTransparentBlue;
  position: fixed;
  width: 100vw;
  height: $navHeightMobile;
  z-index: $zIndexHeader;

  @include media-breakpoint-up(xs) {
    height: $navHeight;
  }

  .container, .row, .logo, img {
    height: 100%;
  }

  .logo {
    svg {
      height: 100%;
      padding: 10px 0;

      .logo-circle {
        stroke: $colorInverted;
      }

      .logo-person {
        fill: $colorInverted;
      }

      .logo-heart {
        fill: $colorInverted;
        transform-origin: center center;
        animation: linear 3s pulse infinite;
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
      fill: #9B1713;
    }
    100% {
      transform: scale(1);
    }
  }

  .mobile-sidebar-button {
    text-align: right;

    .glyphicons {
      font-size: 20px;
      color: $colorLightBlue;
      line-height: $navHeightMobile;

      @include media-breakpoint-up(xs) {
        line-height: $navHeight;
      }

      &:before {
        padding: 0;
      }
    }
  }
}
