.mobile-sidebar {
  text-align: center;
  position: fixed;
  top: $navHeightMobile;
  left: -$mobileSidebarWidth;
  width: $mobileSidebarWidth;
  z-index: $zIndexMobileSidebar;
  background: $colorTransparentBlue;
  min-height: 100vh;
  padding: 20px 30px;
  @include default_transition('left', 'width');

  @include media-breakpoint-up(xs) {
    top: $navHeight;
  }

  nav.mobile {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;

        a {
          display: block;
          padding: 10px 0;

          &.nav-selected {
            background: $colorYellow;
          }
        }
      }
    }
  }
}

.mobile-sidebar-visible {
  .mobile-sidebar {
    left: 0;
    width: 100vw;
  }
}