footer {
  background-color: $colorLightBlue;
  padding: $paddingTopBottom*2 0;
  border-bottom: $paddingTopBottom solid $colorBlue;
  text-align: center;
  color: $colorBlue;
  flex-shrink: 0;

  p {
    padding-bottom: 0;
  }

  .glyphicons {
    border: 1px solid $colorBlue;
    border-radius: 100px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-left: 10px;
    margin-bottom: 10px;
    transition: border 0.3s, color 0.3s;

    &:before {
      padding: 0;
    }
  }

  a {
    transition: color 0.3s;

    &:hover {
      text-decoration: none;
      color: rgba($colorBlue, 0.6);

      span {
        border: 1px solid rgba($colorBlue, 0.6);
        color: rgba($colorBlue, 0.6);
      }
    }
  }

  .center, .right {
    text-align: center;
  }

  .center {
    margin: 20px 0;
  }

  .by-janewi {
    font-size: $fontSizeSmall;
    padding-top: 30px;

    a {
      color: rgba($colorBlue, 0.6);
    }

    img {
      width: 20px;
      margin: 0 0 4px 2px;
      height: auto;
    }
  }
}

@include media-breakpoint-up(xs) {
  footer {
    text-align: left;

    .center {
      text-align: center;
    }

    .right {
      text-align: right;
    }

    .center {
      margin: 0;
    }

    .col-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
