.janewi-events {
  h3 {
    margin-bottom: 0;
  }

  .janewi-events-provisional {
    font-size: 0.6em;
    color: $colorYellow;
    text-transform: uppercase;
  }

  .janewi-events-infos {
    color: $colorBlue;
    font-size: 0.9rem;
    margin-left: -8px;

    .glyphicons::before {
      padding: 4px 8px;
    }
  }
}