.form-message {
  padding: 10px 20px;
  width: 100%;

  &.form-success {
    background: $colorLightBlue;
  }

  &.form-error {
    background: $colorYellow;
  }
}

form {
  .form-control:focus {
    border-color: $colorLightBlue;
    box-shadow: 0 0 0 .1rem $colorLightBlue;
  }

  // honeypot
  input[name="birthday"] {
    display: none;
  }

  input[type="submit"],
  input[type="reset"],
  button {
    background-color: $colorInverted;
    padding: $paddingTopBottom/2.5 $paddingTopBottom;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    box-shadow: 0 .125rem .25rem rgba($colorBlue, .04);
    margin: auto;
    width: auto;
    height: auto;
    color: $colorBlue;
    @include default_transition('color');

    &:hover {
      color: $colorYellow;
    }
  }
}