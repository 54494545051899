$grid-breakpoints: (
  xxxxs: 0,
  xxxs: 480px,
  xxs: 600px,
  xs: 720px,
  sm: 840px,
  md: 960px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1600px,
);

$container-max-widths: (
  xs: 600px,
  sm: 720px,
  md: 840px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
);

$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightMedium: 600;
$fontWeightBold: 700;

$fontSizeSmall: 13px;
$fontSizeText: 16px;

$colorBlue: #004072;
$colorLightBlue: #d9e3ea;
$colorTransparentBlue: rgba($colorBlue, 0.8);
$colorYellow: #9B1713;
$colorHeart: $colorYellow;
$colorText: #222222;
$colorInverted: #ffffff;

$navHeight: 80px;
$navHeightMobile: 60px;

$paddingTopBottom: 20px;

$mobileSidebarWidth: 250px;

$zIndexMobileSidebar: 900;
$zIndexHeader: 1000;
