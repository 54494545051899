nav {
  ul li a {
    color: $colorInverted;
    transition: color 0.3s;

    &:hover {
      color: $colorLightBlue;
    }
  }

  &.desktop {
    ul {
      margin: 0;
      line-height: $navHeight;
      text-align: right;

      li {
        list-style-type: none;
        display: inline-block;
        padding: 0 30px;

        &:last-of-type {
          padding-right: 0;
        }

        &.nav-selected {
          a {
            border-bottom: 3px solid $colorYellow;
            padding-bottom: 27px
          }
        }
      }
    }
  }
}