@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "settings";

@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/forms";
@import "../lib/glyphicons";
@import "../lib/glyphicons-social";

@import "mixins";

html {
  min-height: 100%;
  position: relative;
}

html, body {
  width: 100vw;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
}

@import "mobile-sidebar";

@import "janewi_gallery";

.ccm-page {
  @import "typography";
  @import "header";
  @import "header-image";
  @import "nav";
  @import "alert";
  @import "button";
  @import "forms";
  @import "janewi_eventsblog";
  @import "janewi_peopledirectory";
  @import "janewi_events";
  @import "footer";

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
  }

  main {
    display: block;
    padding-top: $navHeight;
    padding-bottom: 80px;
    flex: 1 0 auto;
  }
}
