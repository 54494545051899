@import "../node_modules/magnific-popup/dist/magnific-popup";

.ccm-page {
  .janewi-gallery {
    margin: 50px auto;

    .gallery-image {
      max-width: calc((100% - 30px) / 4);
      margin-bottom: 10px;

      a {
        border: 0;
      }

      img {
        display: block;
        margin: 0;
      }

      .gallery-image-caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        color: #ffffff;
        padding: 10px 0;
        word-wrap: break-word;

        div {
          margin: 0 10px;
        }
      }
    }
  }

  @include media-breakpoint-down('md') {
    .janewi-gallery {
      .gallery-image {
        max-width: calc((100% - 10px) / 2);
      }
    }
  }
}