.header-image {
  background-image: url("../img/Herzsport-Gruppe-0023.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  margin-top: -$navHeight;

  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
  }

  .main-title {
    position: absolute;
    bottom: 25vh;
    width: 100%;

    h1, p {
      color: $colorInverted;
      background-color: rgba($colorBlue, 0.8);
      padding: 5px 15px 5px 10px;
      display: inline-block;
    }

    h1 {
      font-size: 2em;
    }

    p {
      font-size: 1.3em;
      font-weight: $fontWeightLight;
    }
  }

  @include media-breakpoint-up(xxxs) {
    .main-title {
      h1, p {
        padding: 10px 30px 10px 20px;
      }
    }
  }

  @include media-breakpoint-up(xxs) {
    .main-title {
      h1, p {
        padding: $paddingTopBottom $paddingTopBottom*3 $paddingTopBottom $paddingTopBottom*2;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .main-title {
      h1 {
        font-size: 2em;
      }

      p {
        font-size: 1.5em;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .main-title {
      h1, p {
        padding: $paddingTopBottom $paddingTopBottom*5 $paddingTopBottom $paddingTopBottom*3
      }
      h1 {
        font-size: 2.5em;
      }

      p {
        font-size: 2em;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .main-title {
      h1 {
        font-size: 3.5em;
      }

      p {
        font-size: 2.5em;
      }
    }
  }

}