.janewi-peopledirectory {
  .members {
    margin-top: $paddingTopBottom;
    text-align: center;
    justify-content: center;

    .member {
      margin-bottom: 20px;

      h2, h3, h4, p {
        text-align: center;
      }

      img {
        border-radius: 1000px;
        width: 80%;
        margin: auto;
      }
    }
  }
}
