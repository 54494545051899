@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: $fontWeightNormal;
  font-size: $fontSizeText;
  line-height: 1.6em;
  color: $colorText;
}

h1, h2, h3, h4, h5, h6 {
  color: $colorBlue;
  font-weight: $fontWeightNormal;
  margin: 0;
  padding: 0;
  line-height: normal;
  text-align: left;
}

h1 {
  margin: 30px 0 15px 0;
  font-size: 2em;
}

h2 {
  margin: 30px 0 15px 0;
  font-size: 1.6em;
}

h3 {
  margin: 15px 0 10px;
  font-size: 1.3em;
}

h4, h5, h6 {
  margin: 7px 0 7px;
  font-size: 1.2em;
}

p {
  padding: 0 0 10px 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: $colorBlue;
  cursor: pointer;
  @include default_transition('color');

  &:hover {
    text-decoration: none;
    color: $colorYellow;
  }
}

@include media-breakpoint-up(sm) {
  h1 {
    margin: 40px 0 20px 0;
    font-size: 2.5em;
  }

  h2 {
    margin: 40px 0 20px 0;
    font-size: 2em;
  }

  h3 {
    margin: 20px 0 15px;
    font-size: 1.5em;
  }

  h4, h5, h6 {
    margin: 10px 0 10px;
    font-size: 1.2em;
  }
}